<template>
  <div class="verify" >
    <div class="title">
      <router-link :to="{name:'Home'}">首页</router-link>
      <a>&emsp;<span class="Breadcrumb"><i class="el-icon-arrow-right "></i></span>&emsp;黑号验证</a>
    </div>
    <div v-loading="loading" :class="{'background1':isSafe,'background2':isDanger,'background3':isEmpty,'background4':level1,'background5':level2,'body':true}">
      <div v-if="isSafe" class="tips">该账号暂无拉黑信息，可输入账号重新查询</div>
      <div v-else-if="isDanger" class="tips">{{res.msg}}</div>
<!--      <div v-else class="tips">该账号输入为空或不合法，请重新输入</div>-->
      <div v-else class="tips">{{res.msg}}</div>
      <div class="query_input">
        <input v-model.trim="num" type="text" placeholder="输入账号进行验证">
        <span @click="blackCheck(num)">重新查询</span>
      </div>
    </div>
  </div>
</template>
<script>
  import { apiBlackCheck } from '@/request/API';
export default {
  name: '',
  props: {
  },
  components: {

  },
  data () {
    return {
      num:this.$route.params.num,
      isSafe:false,
      isDanger:false,
      isEmpty:false,
      level1 : false,
      level2 : false,
      pullBlackNum:'',
      res:{},
      loading: false
    }
  },
  methods: {
    queryNum(num){
      switch (num){
        case '1':
          this.isSafe=true
          this.isDanger=false
          this.isEmpty=false
          this.level1 = false
          this.level2 = false
          break;
        case '2':
          this.isSafe = false
          this.isDanger = true
          this.isEmpty = false
          this.level1 = false
          this.level2 = false
          break;
        case '3':
          this.isSafe=false
          this.isDanger=false
          this.isEmpty=true
          this.level1 = false
          this.level2 = false
          break;
        case '4':
          this.isSafe=false
          this.isDanger=false
          this.isEmpty=false
          this.level1 = true
          this.level2 = false
          break;
        case '5':
          this.isSafe=false
          this.isDanger=false
          this.isEmpty=false
          this.level1 = false
          this.level2 = true
          break;
        default:
          this.isSafe=false
          this.isDanger=false
          this.isEmpty=true
          this.level1 = false
          this.level2 = true
          break;
      }
    },
    /**
     * 重新查询按钮
     * @param number
     */
    blackCheck(number){
      this.loading = true
      apiBlackCheck({
        number:number
      }).then(res => {
        this.queryNum('1')
        this.res = res
        this.loading = false
      }).catch(err=>{
        switch (err.code) {
          case 101:
            // this.queryNum('2')
            this.pullBlackNum = this.num
            this.res = err
            switch (err.data.level) {
              case 0:
                this.queryNum('2')
                    break;
              case 1:
                this.queryNum('4')
                break;
              case 2:
                this.queryNum('5')
                break;
            }
            break;
          case 0:
            this.queryNum('3')
            this.res = err
            break;
          case 10001:
            this.queryNum('3')
            this.res = err
            break;
        }
        this.loading = false
      })
    }
  },
  mounted () {
    this. blackCheck(this.num);
  },
  watch: {

  },
  computed: {
  }
}
</script>

<style lang='less' scoped>
.verify{
  width: 1200px;
  .title{
    height: 60px;
    display: flex;
    align-items: center;
    margin-left: 5px;
    a:first-of-type{
      font-size: 16px;
      font-family: PingFangSC-Thin, PingFang SC;
      font-weight: 300;
      color: #8F8F8F;
    }
    a:last-of-type{
      font-size: 16px;
      font-family: PingFangSC-Light, PingFang SC;
      font-weight: 400;
      color: #434ED6;
      cursor: pointer;
    }
    a{
      .Breadcrumb{
        font-size: 12px;
      }
    }
  }
  .body{
    width: 1200px;
    height: 546px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .tips{
      padding: 0 100px;
      height: 56px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      font-size: 20px;
      font-family: PingFangSC-Light, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 28px;
      margin-top: 283px;
    }
    .query_input{
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 50px;
      position: relative;
      input{
        width: 246px;
        height: 50px;
        background: #FFFFFF;
        border-radius: 30px;
        border: none;
        padding: 0 130px 0 24px;
      }
      input::-webkit-input-placeholder {
        font-size: 16px;
        font-family: PingFangSC-Thin, PingFang SC;
        font-weight: 300;
        color: #BFBFBF;
      }
      span{
        position: absolute;
        left: 70%;
        width: 120px;
        height: 50px;
        line-height: 50px;
        background: linear-gradient(180deg, #FFDB51 0%, #FF8435 100%);
        border-radius: 30px;
        font-size: 20px;
        font-family: PingFangSC-Light, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        cursor: pointer;
        text-align: center;
      }
    }
  }
  .background1{
    background: url("../../../assets/image/Verify/blackNum1.png") no-repeat;
  }
  .background2{
    background: url("../../../assets/image/Verify/blackNum5.png") no-repeat;
  }
  .background3{
    background: url("../../../assets/image/Verify/blackNum3.png") no-repeat;
  }
  .background4{
    background: url("../../../assets/image/Verify/blackNum4.png") no-repeat;
  }
  .background5{
    background: url("../../../assets/image/Verify/blackNum2.png") no-repeat;
  }
}
</style>
